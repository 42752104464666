<template>
  <Backoffice>
    <div class="wrapper">
      <main>
        <div class="ui fluid container backoffice-page__content">
          <div class="ui grid">
            <div class="row">
              <h1 class="sixteen column wide">Logs</h1>
            </div>
            <div class="row">
              <section class="log__section sixteen column wide">
                <div class="log__section-table-wrapper">
                  <table class="ui selectable celled table">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Email</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="log in logs" :key="log.id" class="user__list">
                        <td>{{ log.id }}</td>
                        <td>{{ log.customerId }}</td>
                        <td class="d-flex ai-center jc-center">
                          {{ log.status }}
                          <span
                            :class="
                              'bullet__status bullet__status--' +
                                log.status.toLowerCase()
                            "
                          ></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  </Backoffice>
</template>

<script>
import Backoffice from "../../Template/Backoffice";

export default {
  name: "Logs",
  components: {
    Backoffice
  },
  data() {
    return {
      logs: [],
      loading: false
    };
  },
  computed: {},
  methods: {
    getLog() {
      let vm = this;
      vm.$store.dispatch("Log/GET_LOGS").then(_response => {
        console.log("LOGS", _response);
        vm.logs = _response;
      });
    }
  },
  created() {
    let vm = this;
    vm.getLog();
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #595959;
}
.log__section {
  width: 100%;
}
</style>
